<i18n>
ru:
  cardOnDelivery: Картой при получении
  cardOnWebsite: Картой на сайте
  cashToCourier: Наличными курьеру
  changeNeeded: "Нужна сдача с {amount}"
  edit: Изменить
  noChangeNeeded: "Сдача не нужна"
  paymentMethods: Способы оплаты
  sbpPayment: Оплата СБП
ua:
  cardOnDelivery: Карткою при отриманні
  cardOnWebsite: Карткою на сайті
  cashToCourier: Готівкою кур'єру
  changeNeeded: "Потрібна решта з {amount}"
  edit: Змінити
  noChangeNeeded: "Решта не потрібна"
  paymentMethods: Способи оплати
  sbpPayment: Оплата СБП
us:
  cardOnDelivery: Card upon receipt
  cardOnWebsite: Card on the website
  cashToCourier: Cash to the courier
  changeNeeded: "Change needed from {amount}"
  edit: Edit
  noChangeNeeded: "No change needed"
  paymentMethods: Payment methods
  sbpPayment: SBP payment
</i18n>

en: uk: ru:

<template>
  <div class="v-second-step-payments">
    <span
      class="v-typography-title-4 v-mb-md"
      v-html="translate('payMethod.paymentMethods')"
    />

    <transition
      appear
      mode="out-in"
      name="fade"
    >
      <div
        class="v-w-100"
        v-if="availablePaymentTypes.length > 0"
      >
        <arora-carousel
          max-items-auto
          :items="availablePaymentTypes"
          pagination-type="none"
          :space-between="16"
          snap-align="start"
        >
          <template #item="payType: PayVariant">
            <div
              class="v-second-step-payment v-pointer"
              :class="{
                'v-second-step-payment--selected': payType.ID === selectedPaymentId
              }"
              @click="() => (selectedPaymentId = payType.ID)"
            >
              <div class="v-second-step-payment__icon">
                <icon-manual-payment-cash
                  v-if="payType.ID === `pay-type-${PayType.Cash}`"
                />
                <icon-manual-payment-card
                  v-else-if="payType.ID === `pay-type-${PayType.BankCard}`"
                />
                <icon-manual-payment-card-online
                  v-else-if="payType.ID === `pay-type-${PayType.Online}`"
                />
              </div>
              <div class="v-d-flex v-align-items-center v-mt-xs">
                <span
                  class="v-typography-text-4 v-font-weight-500"
                  v-html="payType.Title"
                />
                <div class="v-ml-sm v-second-step-payment__provider-icon">
                  <icon-manual-payment-maestro
                    v-if="
                      payType.PaySystemProvider ===
                        PaymentSystemProvider.MaestroUK ||
                      payType.PaySystemProvider === PaymentSystemProvider.Maestro
                    "
                  />
                  <icon-manual-payment-visa
                    v-else-if="
                      payType.PaySystemProvider === PaymentSystemProvider.Visa
                    "
                  />
                  <icon-manual-payment-mastercard
                    v-else-if="
                      payType.PaySystemProvider === PaymentSystemProvider.Mastercard
                    "
                  />
                  <icon-manual-payment-mir
                    v-else-if="
                      payType.PaySystemProvider === PaymentSystemProvider.MIR
                    "
                  />
                  <icon-manual-payment-jcb
                    v-else-if="
                      payType.PaySystemProvider === PaymentSystemProvider.JCB
                    "
                  />
                </div>
              </div>
            </div>
          </template>
        </arora-carousel>

        <div
          class="v-d-flex v-justify-content-between"
          v-if="
            (clientStore.ClientState.data?.StateOrderData?.PaymentType ?? 0) ===
            PayType.Cash
          "
        >
          <i18n-t
            v-if="change > 0"
            class="v-typography-title-6 v-d-flex"
            keypath="payMethod.changeNeeded"
            scope="global"
            tag="span"
          >
            <template #amount>
              <common-currency
                class="v-ml-xxs"
                :amount="change"
              />
            </template>
          </i18n-t>
          <span
            v-else
            class="v-typography-title-6"
            v-html="translate('payMethod.noChangeNeeded')"
          />

          <ui-button
            :size="14"
            color="secondary"
            type="text-with-button"
            width="content"
            :label="translate('payMethod.edit')"
            :click-event="callForPopup"
          />
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import {
  type GUID,
  type HasID,
  PaymentSystemProvider,
  useBankCard
} from '@arora/common'

import { Guid, PayType } from '~api/consts'

const clientStore = useClientStore()
const popupStore = usePopupStore()

const { sanitize, translate } = useI18nSanitized()
const { getPaymentSystemProvider } = useBankCard()

type PayVariant = HasID & {
  Title: string
  PaySystemProvider: PaymentSystemProvider | null
}

const change = computed<number>(() => {
  const changeString = clientStore.ClientState?.data?.StateOrderData?.Change ?? ''
  return changeString.length > 0 ? Number.parseInt(changeString) : 0
})

async function callForPopup(): Promise<void> {
  await popupStore.openPopup({
    popupClosable: true,
    popupName: 'cartCashChange'
  })
}

const selectedPaymentId = computed<GUID>({
  get: () => {
    if (!clientStore.ClientState.data) return Guid.Empty

    const payType = clientStore.ClientState.data.StateOrderData?.PaymentType ?? 0

    if (
      !Guid.IsNullOrEmpty(
        clientStore.ClientState.data.PaymentBindingState?.CurrentBindingId
      ) &&
      payType === PayType.Online
    ) {
      return clientStore.ClientState.data.PaymentBindingState!.CurrentBindingId!
    }

    return `pay-type-${payType}` as GUID
  },
  set: (value) => {
    if (value.includes('pay-type-')) {
      const payType: PayType = Number.parseInt(value.replaceAll('pay-type-', ''))
      clientStore
        .updateOrderData({
          bindingId: null,
          paymentType: payType,
          refreshState: true
        })
        .then(() => {
          if (payType === PayType.Cash && change.value === 0) callForPopup()
        })
    } else {
      clientStore.updateOrderData({
        bindingId: value,
        paymentType: PayType.Online,
        refreshState: true
      })
    }
  }
})

const availablePaymentTypes = computed<PayVariant[]>(() => [
  ...(clientStore.ClientState.data?.PaymentBindingState?.Bindings ?? []).map(
    (binding) => {
      return {
        ID: binding.BindingId,
        PaySystemProvider: getPaymentSystemProvider(binding.FirstPart),
        Title: sanitize(`∙∙∙∙${binding.LastPart}`)
      }
    }
  ),
  ...(clientStore.ClientState.data?.AvailablePaymentTypes ?? []).map((payType) => {
    let title = ''

    switch (payType.Type) {
      case PayType.Cash: {
        title = translate('payMethod.cashToCourier')
        break
      }
      case PayType.Online: {
        title = translate('payMethod.cardOnWebsite')
        break
      }
      case PayType.BankCard: {
        title = translate('payMethod.cardOnDelivery')
        break
      }
      case PayType.QR: {
        title = translate('payMethod.sbpPayment')
        break
      }
    }

    return {
      ID: `pay-type-${payType.Type}` as GUID,
      PaySystemProvider: null,
      Title: title
    }
  })
])
</script>

<style lang="scss">
@use 'assets/variables';

.v-second-step-payments {
  .v-second-step-payment {
    padding: 0.855rem 1.145rem;
    border-radius: variables.$BorderRadius;
    border: 2px solid variables.$Mono100;
    min-height: 60px;
    height: 100%;

    &--selected {
      border-color: variables.$SecondaryBackgroundColor;
    }

    &__icon {
      width: 28px;
      height: 28px;
      padding: 4px 0;
    }

    &__provider-icon {
      width: 38px;
      flex: 0 0 38px;
    }
  }
}
</style>
